import React from 'react';
import useAuth from './AuthContext';
import {useMount} from 'ahooks';
import {UserHook} from '../hooks/User';

export default function AppInit(props: {children?: any}) {
  const auth = useAuth();
  const fetchByToken = UserHook.fetchByToken();
  useMount(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchByToken
        .request({token})
        .then(res => {
          localStorage.setItem('token', token);
          auth.setToken(token);
          auth.setCurrentUser(res);
        })
        .catch(console.log);
    }
  });
  return <>{props.children}</>;
}
