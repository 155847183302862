import React, {useEffect, useState} from 'react';
import {AllArticleTypes, ArticleHook, ArticleType} from '../../hooks/Article';
import {Cell, Panel, Radio, Select, Toast} from 'zarm';
import {useMount} from 'ahooks';
import moment from 'moment';
import {useSearchParam} from 'react-use';
import {useHistory} from 'react-router-dom';

export default function ArticleIndexPage() {
  const [pi, setPi] = useState(1);
  const queryType = useSearchParam('type');
  const [type, setType] = useState<ArticleType>((queryType as ArticleType) || '活动简介');
  const searchHook = ArticleHook.search();
  const history = useHistory();

  function search(pi: number) {
    searchHook
      .request({type, pi, ps: 30})
      .then()
      .catch(e => Toast.show(e.message));
  }
  useEffect(() => {
    search(1);
  }, [type]);
  return (
    <div>
      <Cell>
        <Radio.Group block type="button" onChange={e => setType(e as ArticleType)} value={type}>
          {AllArticleTypes.filter((_, i) => i < 3).map(i => (
            <Radio value={i} key={i}>
              {i}
            </Radio>
          ))}
        </Radio.Group>
      </Cell>
      <Cell>
        <Radio.Group block type="button" onChange={e => setType(e as ArticleType)} value={type}>
          {AllArticleTypes.filter((_, i) => i >= 3).map(i => (
            <Radio value={i} key={i}>
              {i}
            </Radio>
          ))}
        </Radio.Group>
      </Cell>
      <Panel title={type}>
        {searchHook.data.list.map(article => (
          <Cell
            key={article.id}
            onClick={() => history.push('/article/' + article.id)}
            description={<span style={{fontSize: 14}}>{moment(article.publishedTime).format('YYYY 年 MM 月 DD 日')}</span>}>
            {article.title}
          </Cell>
        ))}
      </Panel>
    </div>
  );
}
