import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {ArticleHook, ArticleType} from '../../hooks/Article';
import {useMount} from 'ahooks';
import {Badge, Toast} from 'zarm';
import Flex from '../Flex';
import {useHistory} from 'react-router-dom';

export default function ArticleCard(props: {type: ArticleType}) {
  const searchHook = ArticleHook.search();
  const history = useHistory();
  useMount(() => {
    searchHook
      .request({type: props.type, pi: 1, ps: 5})
      .then()
      .catch(e => Toast.show(e.message));
  });

  return (
    <div className="ArticleCard p-t-10 p-b-10">
      <div className="header">
        <span className="title">{props.type}</span>
        <div className="more text-gray" onClick={() => history.push('/article?type=' + props.type)}>
          查看更多
        </div>
      </div>
      <div className="body">
        {searchHook.data?.list.map((item, index) => (
          <div key={item.id} className="article-line" onClick={() => history.push('/article/' + item.id)}>
            {index === 0 && (
              <div className="first-line">
                <div className="calendar">
                  <div className="month">{moment(item.createdTime).format('YYYY-MM')}</div>
                  <div className="date">{moment(item.createdTime).format('DD')}</div>
                </div>
                <div className="first-line-body">
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                    {item.isTop && <Badge shape="round" text="置顶" className={'m-r-5'} />}
                    <span>{item.title}</span>
                  </div>
                  <span className="description text-gray">{item.description}</span>
                </div>
              </div>
            )}
            {index !== 0 && (
              <div className="normal-line">
                <div>
                  {item.isTop && <Badge shape="round" text="置顶" className={'m-r-5'} />}
                  <span className="title">{item.title}</span>
                </div>
                <span className="createdTime text-gray">{moment(item.createdTime).format('YYYY-MM-DD')}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
