import * as React from 'react';
function Flex(props: {
  children?: any;
  direction?: 'column' | 'row';
  align?: 'center' | 'flex-start' | 'flex-end' | 'middle';
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  className?: string;
  style?: React.CSSProperties;
}) {
  const style: React.CSSProperties = {
    display: 'flex',
  };
  if (props.direction) style.flexDirection = props.direction;
  if (props.align) style.alignItems = props.align;
  if (props.justify) style.justifyContent = props.justify;

  return (
    <div style={{...style, ...props.style}} className={props.className}>
      {props.children}
    </div>
  );
}
export default Flex;
