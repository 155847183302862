import * as React from 'react';
import Flex from './Flex';
import errorImage from '../assets/error-server.png';
import {useLocation} from 'react-router-dom';
export default function CustomerError(props: {title?: string; statusCode: number}) {
  const location = useLocation();
  return (
    <Flex direction={'column'} align={'center'} justify={'center'} style={{height: 300}}>
      <img src={errorImage} alt="error" style={{width: 200, marginBottom: 10}} />
      <h2>{props.title}</h2>
      <Flex align={'center'}>
        <a href="/" className={'m-r-15'}>
          回到首页
        </a>
        <a href={'/login?callback=' + window.location.href}>前往登录页面</a>
      </Flex>
    </Flex>
  );
}
