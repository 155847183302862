import React, {useState} from 'react';
import {Button, Cell, DateSelect, FilePicker, Icon, Input, Message, NoticeBar, Panel, Radio, Select, Toast} from 'zarm';
import {useInput} from 'react-hanger';
import {IUser, IUserUpdateAuthenticationParams} from '../../hooks/User';
import moment from 'moment';
import {useAxios} from '../../Http/AxiosProvider';
import useAuth from '../../utils/AuthContext';

export default function AuthenticationForm(props: {
  user: IUser;
  onFinish?: (params: IUserUpdateAuthenticationParams) => any;
  cancel?: () => any;
  loading?: boolean;
  readonly?: boolean;
}) {
  const legalName = useInput(props.user.legalName);
  const gender = useInput(props.user.gender || '男');
  const [birthDate, setBirthDate] = useState(moment(props.user.birthDate || '2005-01-01').toDate());
  const credentialType = useInput(props.user.credentialType || '身份证');
  const credentialCode = useInput(props.user.credentialCode);
  const contactTelephone = useInput(props.user.contactTelephone);
  const contactEmail = useInput(props.user.contactEmail);
  const schoolLevel = useInput(props.user.schoolLevel || '小学');
  const schoolName = useInput(props.user.schoolName);
  const schoolEntranceYear = useInput(props.user.schoolEntranceYear || '2018');
  const imagePath = useInput(props.user.studentCardImageList[0]?.path);
  const imageUrl = useInput(props.user.studentCardImageList[0]?.url);
  const [isUploading, setIsUploading] = useState(false);
  const years: number[] = [];
  for (let i = moment().get('year'); i >= 2012; i--) {
    years.push(i);
  }
  const axios = useAxios();
  const auth = useAuth();
  function uploadImage(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    setIsUploading(true);
    axios.instance
      .post<{url: string; path: string}>('uploadImage', formData, {
        headers: {'yacs-token': auth.token},
      })
      .then(res => {
        imagePath.setValue(res.data.path);
        imageUrl.setValue(res.data.url);
        setIsUploading(false);
      })
      .catch(e => {
        setIsUploading(false);
        Toast.show('照片上传失败，请确保格式为 jpg/png，大小不超过 2m');
        console.log(e);
      });
  }

  function submit() {
    if (!imagePath.value) return Toast.show('请上传学生证');
    if (props.onFinish) {
      props.onFinish({
        legalName: legalName.value,
        gender: gender.value as any,
        birthDate: moment(birthDate).format('YYYY-MM-DD'),
        credentialType: credentialType.value as any,
        credentialCode: credentialCode.value,
        schoolLevel: schoolLevel.value as any,
        schoolEntranceYear: schoolEntranceYear.value,
        schoolName: schoolName.value,
        contactEmail: contactEmail.value,
        contactTelephone: contactTelephone.value,
        studentCardImagePathList: [imagePath.value],
        authenticateType: '选手',
      });
    }
  }

  return (
    <div>
      {!props.readonly && (
        <div>
          <NoticeBar>如需教练认证请前往电脑端操作</NoticeBar>
          <h2 className={'m-l-15 m-b-0'}>实名认证</h2>
        </div>
      )}

      <Panel title="个人信息">
        <Cell title="真实姓名">
          <Input type="text" placeholder="请输入真实姓名" value={legalName.value} onChange={(e: any) => legalName.setValue(e)} readOnly={props.readonly} />
        </Cell>
        <Cell title={'性别'}>
          <Radio.Group value={gender.value} onChange={(e: any) => gender.setValue(e)} disabled={props.readonly}>
            <Radio value="男">男</Radio>
            <Radio value="女">女</Radio>
          </Radio.Group>
        </Cell>
        <Cell title={'出生日期'}>
          <DateSelect placeholder="请选择出生日期" mode="date" value={birthDate} onOk={setBirthDate} disabled={props.readonly} />
        </Cell>
        <Cell title={'证件类型'}>
          <Radio.Group value={credentialType.value} onChange={(e: any) => credentialType.setValue(e)} disabled={props.readonly}>
            <Radio value="身份证">身份证</Radio>
            <Radio value="回乡证">回乡证</Radio>
            <Radio value="护照">护照</Radio>
          </Radio.Group>
        </Cell>
        <Cell title="证件号码">
          <Input
            type="idcard"
            placeholder="请输入证件号码"
            value={credentialCode.value}
            onChange={(e: any) => credentialCode.setValue(e)}
            readOnly={props.readonly}
          />
        </Cell>
        <Cell title="联系电话">
          <Input
            type="number"
            placeholder="选填，若实名认证遇到问题用于联系"
            value={contactTelephone.value}
            onChange={(e: any) => contactTelephone.setValue(e)}
            readOnly={props.readonly}
          />
        </Cell>
        <Cell title="联系邮箱">
          <Input
            placeholder="选填，若实名认证遇到问题用于联系"
            value={contactEmail.value}
            onChange={(e: any) => contactEmail.setValue(e)}
            readOnly={props.readonly}
          />
        </Cell>
      </Panel>
      <Panel title="学校信息">
        <Cell title={'当前在读'}>
          <Radio.Group value={schoolLevel.value} onChange={(e: any) => schoolLevel.setValue(e)} disabled={props.readonly}>
            <Radio value="小学">小学</Radio>
            <Radio value="初中">初中</Radio>
            <Radio value="高中">高中</Radio>
          </Radio.Group>
        </Cell>
        <Cell title="学校名称">
          <Input placeholder="请输入完整的学校名称" value={schoolName.value} onChange={(e: any) => schoolName.setValue(e)} readOnly={props.readonly} />
        </Cell>
        <Cell title="入学年份">
          <Select
            value={schoolEntranceYear.value}
            wheelDefaultValue={schoolEntranceYear.value}
            dataSource={years.map(y => ({value: y.toString(), label: y})) as any}
            disabled={props.readonly}
            onOk={(selected: any) => {
              schoolEntranceYear.setValue(selected[0].value);
            }}
          />
        </Cell>
        <Cell title="上传学生证">
          <FilePicker
            className="file-picker-btn"
            disabled={props.readonly || isUploading}
            onChange={(e: any) => {
              uploadImage(e.file);
            }}>
            {imageUrl.value ? <img src={imageUrl.value} style={{width: 200, margin: 10}} alt="" /> : <Button size={'sm'}>点此上传</Button>}
          </FilePicker>
        </Cell>
        <Message theme={'success'}>
          * 学生证需包含学校名称、学生姓名、入学时间，且清晰可见。如果没有学生证，可以用身份证、户口本、护照、入学通知或在读证明代替。
        </Message>
      </Panel>
      {!props.readonly && (
        <div className={'p-15 text-center'}>
          <Button theme={'primary'} className={'m-r-15'} loading={props.loading} disabled={props.loading} onClick={submit}>
            提交审核
          </Button>
          <Button onClick={props.cancel}>返回</Button>
        </div>
      )}
    </div>
  );
}
