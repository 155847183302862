import React from 'react';
import {useBoolean, useInput} from 'react-hanger';
import {IUser, UserHook} from '../hooks/User';
import md5 from 'md5';
import {Button, Cell, Input, Message, Toast} from 'zarm';
import RequestSMSButton from '../components/RequestSMSButton';
import Flex from '../components/Flex';
import {useHistory} from 'react-router-dom';
import {log} from 'util';
import useAuth from '../utils/AuthContext';
import {useSearchParam} from 'react-use';
const LoginPage: React.FunctionComponent<any> = props => {
  const username = useInput('');
  const password = useInput('');
  const telephone = useInput('');
  const code = useInput('');
  const loginHook = UserHook.login();
  const loginByTelHook = UserHook.loginWithTel();
  const isLoginByTel = useBoolean(false);
  const history = useHistory();
  const auth = useAuth();
  const callbackUrl = useSearchParam('callback');

  function loginSuccess(res: {token: string; user: IUser}) {
    auth.setToken(res.token);
    auth.setCurrentUser(res.user);
    localStorage.setItem('token', res.token);
    Toast.show('登录成功');
    if (callbackUrl) {
      window.location.replace(callbackUrl);
    } else {
      history.push('/');
    }
  }

  function login() {
    loginHook
      .request({username: username.value, password: md5(password.value + 'yacs')})
      .then(loginSuccess)
      .catch(e => Toast.show(e.message));
  }
  function loginByTel() {
    loginByTelHook
      .request({telephone: telephone.value, code: code.value})
      .then(loginSuccess)
      .catch(e => Toast.show(e.message));
  }

  return (
    <div>
      <h2 className={'m-l-15'}>登录</h2>
      {isLoginByTel.value ? (
        <div>
          <Cell title="手机号">
            <Input value={telephone.value} onChange={(e: any) => telephone.setValue(e)} type="number" placeholder="请输入手机号" />
          </Cell>
          <Cell title="验证码" description={<RequestSMSButton telephone={telephone.value} />}>
            <Input value={code.value} onChange={(e: any) => code.setValue(e)} type="number" placeholder="请输入验证码" />
          </Cell>
        </div>
      ) : (
        <div>
          <Cell title="用户名">
            <Input value={username.value} onChange={(e: any) => username.setValue(e)} type="text" placeholder="请输入用户名" />
          </Cell>
          <Cell title="密码">
            <Input value={password.value} onChange={(e: any) => password.setValue(e)} clearable type="password" placeholder="请输入密码" />
          </Cell>
        </div>
      )}
      <Flex className={'p-15'}>
        <Button
          block
          className={'m-r-15'}
          theme={'primary'}
          onClick={() => {
            isLoginByTel.value ? loginByTel() : login();
          }}>
          立即登录
        </Button>
        <Button
          block
          onClick={() => {
            if (callbackUrl) {
              history.push('/register?callback=' + callbackUrl);
            } else {
              history.push('/register');
            }
          }}>
          注册新账号
        </Button>
      </Flex>
      <div className={'text-center'}>
        <a onClick={isLoginByTel.toggle}>使用{isLoginByTel.value ? '用户名密码' : '手机号'}登录</a>
      </div>
    </div>
  );
};
export default LoginPage;
