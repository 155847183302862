import React from 'react';
import {Button, Cell, Input, Message, Toast} from 'zarm';
import {useInput} from 'react-hanger';
import {UserHook} from '../hooks/User';
import {useCountDown} from 'ahooks';
import md5 from 'md5';
import RequestSMSButton from '../components/RequestSMSButton';
import {useHistory} from 'react-router-dom';
import useAuth from '../utils/AuthContext';
import {useSearchParam} from 'react-use';

const RegisterPage: React.FunctionComponent<any> = props => {
  const username = useInput('');
  const password = useInput('');
  const telephone = useInput('');
  const code = useInput('');
  const registerHook = UserHook.register();
  const history = useHistory();
  const loginHook = UserHook.login();
  const auth = useAuth();
  const callbackUrl = useSearchParam('callback');
  function register() {
    registerHook
      .request({telephone: telephone.value, code: code.value, username: username.value, password: md5(password.value + 'yacs')})
      .then(res => {
        Toast.show('注册成功');
        loginHook
          .request({username: username.value, password: md5(password.value + 'yacs')})
          .then(res => {
            auth.setToken(res.token);
            auth.setCurrentUser(res.user);
            localStorage.setItem('token', res.token);
            if (callbackUrl) {
              window.location.replace(callbackUrl);
            } else {
              history.push('profile/authentication');
            }
          })
          .catch(e => Toast.show(e.message));
      })
      .catch(e => Toast.show(e.message));
  }

  return (
    <div>
      <h2 className={'m-l-15'}>注册新账号</h2>
      <Cell title="用户名">
        <Input value={username.value} onChange={(e: any) => username.setValue(e)} type="text" placeholder="请输入用户名" />
      </Cell>
      <Cell title="密码">
        <Input value={password.value} onChange={(e: any) => password.setValue(e)} clearable type="password" placeholder="请输入密码" />
      </Cell>
      <Cell title="手机号">
        <Input value={telephone.value} onChange={(e: any) => telephone.setValue(e)} type="number" placeholder="请输入手机号" />
      </Cell>
      <Message theme={'success'}>* 手机号用于登录和找回密码</Message>
      <Cell title="验证码" description={<RequestSMSButton telephone={telephone.value} />}>
        <Input value={code.value} onChange={(e: any) => code.setValue(e)} type="number" placeholder="请输入验证码" />
      </Cell>
      <div className={'p-15'}>
        <Button block theme={'primary'} onClick={register}>
          立即注册
        </Button>
        <Button block theme="primary" href={'/login' + (callbackUrl ? '?callback=' + callbackUrl : '')}>
          已有账号，前往登录
        </Button>
      </div>
    </div>
  );
};
export default RegisterPage;
