import React, {useMemo, useState} from 'react';
import logo from './logo.svg';
import './styles/app.less';
import './styles/markdown.less';
import 'normalize.css/normalize.css';
import 'snack-helper/dist/snack-helper.css';
import 'zarm/dist/zarm.css';
import LoginPage from './pages/login';
import {AuthProvider, Alert, useAuthContext} from 'geestack-toolbox';
import {BrowserRouter, Switch, Route, Link} from 'react-router-dom';
import RegisterPage from './pages/register';
import AxiosProvider from './Http/AxiosProvider';
import ApiInstance from './utils/ApiInstance';
import AppInit from './utils/AppInit';
import ProfileAuthenticationPage from './pages/profile/authentication';
import LayoutTabBar from './components/layouts/TabBar';
import ProfileIndexPage from './pages/profile';
import IndexPage from './pages';
import PageHeader from './components/layouts/PageHeader';
import ArticleIndexPage from './pages/article';
import ArticleDetailPage from './pages/article/[id]';
import KnowledgeContestAttendPage from './pages/knowledgeContest2022/attend';
import KnowledgeContest2022IndexPage from './pages/knowledgeContest2022';
document.documentElement.style.setProperty('--theme-primary', '#E564AC');
document.documentElement.style.setProperty('--theme-primary-dark', '#9d3971');
document.documentElement.style.setProperty('--tabbar-height', 'auto');

const App: React.FunctionComponent<any> = () => {
  return (
    <AuthProvider>
      <AxiosProvider instance={ApiInstance} baseUrl={'https://api.iai.sh.cn'}>
        <AppInit>
          <BrowserRouter>
            <PageHeader />
            <div className={'p-b-50'}>
              <Switch>
                <Route path={'/login'} component={LoginPage} />
                <Route path={'/register'} component={RegisterPage} />
                <Route path={'/profile/authentication'} component={ProfileAuthenticationPage} />
                <Route path={'/profile'} component={ProfileIndexPage} />
                <Route path={'/article/:id'} component={ArticleDetailPage} />
                <Route path={'/article'} component={ArticleIndexPage} />
                <Route path={'/knowledgeContest2022/attend'} component={KnowledgeContestAttendPage} />
                <Route path={'/knowledgeContest2022'} component={KnowledgeContest2022IndexPage} />
                <Route path={'/'} component={IndexPage} />
              </Switch>
            </div>
            <LayoutTabBar />
          </BrowserRouter>
        </AppInit>
      </AxiosProvider>
    </AuthProvider>
  );
};

export default App;
