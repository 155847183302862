import React from 'react';
import {Button, Cell, Icon, Panel} from 'zarm';
import useAuth from '../../utils/AuthContext';
import {useHistory} from 'react-router-dom';
import Username from '../../components/Profile/Username';
import Flex from '../../components/Flex';
import UserAvatar from '../../components/Profile/UserAvatar';

export default function ProfileIndexPage() {
  const auth = useAuth();
  const history = useHistory();
  function logout() {
    auth.logout();
    localStorage.removeItem('token');
  }
  return (
    <div>
      {auth.currentUser ? (
        <div>
          <Flex direction={'column'} justify={'center'} align={'center'} className={'p-t-20'}>
            <UserAvatar config={auth.currentUser.avatarConfig} size={80} />
            <Username user={auth.currentUser} className={'m-t-10'} />
          </Flex>
          <Panel title={'个人中心'}>
            <Cell
              title={'实名认证'}
              onClick={() => {
                history.push('/profile/authentication');
              }}
            />
          </Panel>
          <div className={'p-15'}>
            <Button theme={'danger'} block onClick={logout}>
              注销
            </Button>
          </div>
        </div>
      ) : (
        <div className={'text-center p-35 bg-white'}>
          <Icon type={'info-round-fill'} theme="primary" style={{fontSize: 80}} />
          <h2>你还未登录</h2>
          <p className={'text-gray'}>登录账号以进行实名认证，报名等操作</p>
          <Button
            theme={'primary'}
            className={'m-t-10'}
            onClick={() => {
              history.push('/login?callback=' + window.location.href);
            }}>
            登录/注册
          </Button>
        </div>
      )}
    </div>
  );
}
