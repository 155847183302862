import * as React from 'react';
import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

export default function PageHeader() {
  const location = useLocation();

  const visible = useMemo(() => {
    if (location.pathname === '/knowledgeContest2021') return false;
    return true;
  }, [location.pathname]);
  if (!visible) return <div />;
  return (
    <div className="PageHeader bg-white">
      <img src="https://iai.sh.cn/images/scs-logo.jpg" alt="logo" className="scs-logo" />
      <img src="https://iai.sh.cn/images/logo.png" alt="logo" className="header-logo" />
      <div className="header-title-container">
        <span className="header-title">上海市计算机学会竞赛平台</span>
        <span className="header-subtitle text-gray">Shanghai Computer Society Contest Platform</span>
      </div>
    </div>
  );
}
