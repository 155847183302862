import React, {useEffect, useState} from 'react';
import {useSearchParam} from 'react-use';
import {Button, Cell, Icon, Input, Message, Toast} from 'zarm';
import useAuth from '../../utils/AuthContext';
import website from '../../assets/website.jpg';
import AuthenticationForm from '../../components/Profile/AuthenticationForm';
import {IUserUpdateAuthenticationParams, UserHook} from '../../hooks/User';
import {useBoolean, useInput} from 'react-hanger';
import ContestAuthenticationForm from '../../components/KnowledgeContest2021/ContestAuthenticationForm';
import RequestSMSButton from '../../components/RequestSMSButton';
import Flex from '../../components/Flex';
import md5 from 'md5';
function JustOpenForChildren() {
  return (
    <div className={'text-center p-35 bg-white'}>
      <Icon type={'info-round-fill'} theme="primary" style={{fontSize: 80}} />
      <h2>报名失败</h2>
      <p className={'text-gray'}>很抱歉，本次比赛仅对中小学生开放</p>
    </div>
  );
}
const KnowledgeContestAttendPage: React.FunctionComponent<any> = props => {
  const [step, setStep] = useState<'init' | 'login' | 'register' | 'authenticate' | 'success'>('init');
  const auth = useAuth();

  const telephone = useInput('');
  const code = useInput('');
  const username = useInput('');
  const password = useInput('');
  const registerHook = UserHook.register();
  const loginByTelHook = UserHook.loginWithTel();
  const updateAuthenticationHook = UserHook.updateAuthentication();
  const attendHook = UserHook.attendKnowledgeContest2022();
  const loginHook = UserHook.login();
  function loginByTel() {
    loginByTelHook
      .request({telephone: telephone.value, code: code.value})
      .then(res => {
        auth.setToken(res.token);
        auth.setCurrentUser(res.user);
        localStorage.setItem('token', res.token);
      })
      .catch(e => Toast.show(e.message));
  }
  function register() {
    registerHook
      .request({telephone: telephone.value, code: code.value, username: username.value, password: md5(password.value + 'yacs')})
      .then(res => {
        Toast.show('注册成功');
        loginHook
          .request({username: username.value, password: md5(password.value + 'yacs')})
          .then(res => {
            auth.setToken(res.token);
            auth.setCurrentUser(res.user);
            localStorage.setItem('token', res.token);
          })
          .catch(e => Toast.show(e.message));
      })
      .catch(e => Toast.show(e.message));
  }
  function updateAuthentication(params: IUserUpdateAuthenticationParams) {
    updateAuthenticationHook
      .request(params)
      .then(res => {
        auth.setCurrentUser(res);
        Toast.show('已提交审核');
      })
      .catch(e => {
        Toast.show(e.message);
      });
  }

  useEffect(() => {
    if (auth.currentUser) {
      if (!auth.currentUser.isAttendKnowledge2022) {
        attendHook.request({}).then(() => {
          if (auth.currentUser) {
            auth.setCurrentUser({...auth.currentUser, isAttendKnowledge2022: true});
          }
        });
      } else {
        setStep('authenticate');
      }
    } else {
      setStep('init');
    }
  }, [auth.currentUser]);

  return (
    <div>
      <div className={'bg-white  p-t-20 p-b-20'}>
        <div className={'m-b-30 m-l-15'}>
          <div>欢迎报名</div>
          <div>上海市青少年计算机知识竞赛</div>
        </div>
        {step === 'init' && (
          <div className={'m-l-15 m-r-15'}>
            <div style={{marginBottom: 30}}>
              <img src={website} style={{width: '60%', marginBottom: 15, border: '1px solid #e5e5e5'}} alt="" />
              <h3 className={'m-b-15'}>请问你之前是否已经注册过本学会竞赛平台的账号?</h3>
              <div style={{color: '#bababa'}}>上海市计算机学会竞赛平台每月举办编程算法月赛，如果曾经注册过，可使用之前的账号报名。</div>
            </div>
            <Button onClick={() => setStep('login')} className={'m-b-5'} block>
              已有账号，直接报名
            </Button>
            <Button onClick={() => setStep('register')} block>
              第一次使用，注册新账号
            </Button>
          </div>
        )}
        {step === 'login' && (
          <div>
            <h3 className={'m-l-15 m-b-15'}>请登录你的账号</h3>
            <Cell title="手机号">
              <Input value={telephone.value} onChange={(e: any) => telephone.setValue(e)} type="number" placeholder="请输入手机号" />
            </Cell>
            <Cell title="验证码" description={<RequestSMSButton telephone={telephone.value} />}>
              <Input value={code.value} onChange={(e: any) => code.setValue(e)} type="number" placeholder="请输入验证码" />
            </Cell>
            <Flex className={'p-15'}>
              <Button block className={'m-r-15'} theme={'primary'} onClick={loginByTel}>
                立即登录
              </Button>
              <Button
                block
                onClick={() => {
                  setStep('init');
                }}>
                返回
              </Button>
            </Flex>
          </div>
        )}
        {step === 'register' && (
          <div>
            <h3 className={'m-l-15 m-b-15'}>注册用于参赛的新账号</h3>
            <Cell title="用户名">
              <Input value={username.value} onChange={(e: any) => username.setValue(e)} type="text" placeholder="请输入用户名" />
            </Cell>
            <Cell title="密码">
              <Input value={password.value} onChange={(e: any) => password.setValue(e)} clearable type="password" placeholder="请输入密码" />
            </Cell>
            <Cell title="手机号">
              <Input value={telephone.value} onChange={(e: any) => telephone.setValue(e)} type="number" placeholder="请输入手机号" />
            </Cell>
            <Cell title="验证码" description={<RequestSMSButton telephone={telephone.value} />}>
              <Input value={code.value} onChange={(e: any) => code.setValue(e)} type="number" placeholder="请输入验证码" />
            </Cell>
            <Flex className={'p-15'}>
              <Button block className={'m-r-15'} theme={'primary'} onClick={register}>
                立即注册
              </Button>
              <Button
                block
                onClick={() => {
                  setStep('init');
                }}>
                返回
              </Button>
            </Flex>
          </div>
        )}
        {step === 'authenticate' && auth.currentUser && (
          <div>
            {!auth.currentUser.isAttendKnowledge2022 && (
              <div className={'text-center p-35 bg-white'}>
                <Icon type={'time'} theme="primary" style={{fontSize: 80}} />
                <h2>报名中</h2>
              </div>
            )}
            {auth.currentUser.isAttendKnowledge2022 && (
              <div>
                <div>
                  <div className={'text-center p-35 bg-white'}>
                    <Icon type={'right-round-fill'} theme="success" style={{fontSize: 80}} />
                    <h2>报名完成</h2>
                    {auth.currentUser.authenticateStatus === '未认证' && (
                      <div>
                        <p className={'text-gray'}>你可以继续完善个人信息，以参与后续的排名和证书申请</p>
                        <Button theme={'primary'} href={'/profile/authentication'}>
                          点此前往完善
                        </Button>
                      </div>
                    )}
                    {auth.currentUser.authenticateStatus === '认证中' && (
                      <div>
                        <p className={'text-gray'}>你的个人信息正在审核中</p>
                        <Button theme={'primary'} href={'/profile/authentication'}>
                          点此查看
                        </Button>
                      </div>
                    )}
                    {auth.currentUser.authenticateStatus === '已认证' && (
                      <div>
                        <p className={'text-gray'}>你已完成报名流程和实名认证</p>
                        <Button theme={'primary'} href={'/profile/authentication'}>
                          点此查看实名信息
                        </Button>
                      </div>
                    )}
                    {auth.currentUser.authenticateStatus === '认证失败' && (
                      <div>
                        <p className={'text-gray'}>请注意，你的个人信息审核失败，请完善个人信息，以参与后续的排名和证书申请</p>
                        <Button theme={'primary'} href={'/profile/authentication'}>
                          点此查看审核信息
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {/*{auth.currentUser.authenticateStatus === '未认证' && (*/}
                {/*  <div>*/}
                {/*    <h3 className={'m-l-15 m-b-5'}>请填写下方报名表</h3>*/}
                {/*    <ContestAuthenticationForm user={auth.currentUser} loading={updateAuthenticationHook.loading} onFinish={updateAuthentication} />*/}
                {/*  </div>*/}
                {/*)}*/}
                {/*{auth.currentUser.authenticateStatus === '认证失败' && (*/}
                {/*  <div>*/}
                {/*    <h3 className={'m-l-15 m-b-5'}>实名认证失败，请重新填写下列信息</h3>*/}
                {/*    <p className={'m-l-15 m-b-5 text-gray'}>失败原因：{auth.currentUser.authenticationRejectRemark}</p>*/}
                {/*    <ContestAuthenticationForm user={auth.currentUser} loading={updateAuthenticationHook.loading} onFinish={updateAuthentication} />*/}
                {/*  </div>*/}
                {/*)}*/}
                {/*{auth.currentUser.authenticateStatus === '认证中' && (*/}
                {/*  <div>*/}
                {/*    <div className={'text-center p-35 bg-white'}>*/}
                {/*      <Icon type={'time'} theme="primary" style={{fontSize: 80}} />*/}
                {/*      <h2>实名认证中</h2>*/}
                {/*      <p className={'text-gray'}>你已完成报名，实名认证审核中</p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}
                {/*{auth.currentUser.authenticateStatus === '已认证' && (*/}
                {/*  <div>*/}
                {/*    <div className={'text-center p-35 bg-white'}>*/}
                {/*      <Icon type={'right-round-fill'} theme="success" style={{fontSize: 80}} />*/}
                {/*      <h2>报名完成</h2>*/}
                {/*      <p className={'text-gray'}>你已完成报名，并通过了实名认证</p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default KnowledgeContestAttendPage;
