import usePost from '../Http/usePost';
import useGet from '../Http/useGet';
import {
  IUser,
  IUserFetchByTokenParams,
  IUserLoginParams,
  IUserLoginWithTelParams,
  IUserRegisterParams,
  IUserRequestSMSCodeParams,
  IUserUpdateAuthenticationParams,
} from './User';

export interface IArticle {
  id: number;
  createdTime: string;
  updatedTime: string;
  title: string;
  content: string;
  description: string;
  publishedTime: string;
  authorName: string;
  authorOrganizationName: string;
  coverUrl: string;
  type: ArticleType;
  isTop: boolean;
  isShow: boolean;
}

export type ArticleType = '通知公告' | '经验分享' | '活动简介' | '条例条规' | '获奖名单' | '教师交流';
export const AllArticleTypes: ArticleType[] = ['活动简介', '条例条规', '通知公告', '获奖名单', '教师交流', '经验分享'];
export interface IArticleSearchParams {
  keyword?: string;
  type?: string;
  pi?: number;
  ps?: number;
  includeHide?: boolean;
  sorterKey?: string;
  sorterOrder?: 'DESC' | 'ASC';
  isShow?: boolean;
  isTop?: boolean;
}
export class ArticleHook {
  static search() {
    return useGet<IArticleSearchParams, {totalCount: number; list: IArticle[]}>({url: 'article/search', defaultValue: {totalCount: 0, list: []}});
  }
  static queryById() {
    return useGet<{id: number}, IArticle>({url: 'article/queryById'});
  }
}
