import axios from 'axios';
const ApiInstance = axios.create({
  timeout: 120000,
});
ApiInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    let message = '请求出错';
    if (!error.response) {
      message = '连接服务器出错，请稍后再试';
    } else if (error.response.status === 400) {
      message = error.response.data.message;
    } else if (error.response.status === 401) {
      message = error.response.data.message;
    } else if (error.response.status === 404) {
      message = '未找到相关页面';
    } else if (error.response.status === 403) {
      message = error.response.data.message;
    } else if (error.response.status === 412) {
      const messages = [];
      for (const key in error.response.data.data) {
        if (error.response.data.data.hasOwnProperty(key)) {
          messages.push(error.response.data.data[key]);
        }
      }
      message = messages[0];
    } else if (!error.statusCode) {
      message = '连接服务器出错，请稍后再试';
    }

    return Promise.reject(new Error(message));
  },
);
export default ApiInstance;
