import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {ArticleHook} from '../../hooks/Article';
import {Toast} from 'zarm';
import moment from 'moment';
import MarkdownRender from '../../components/MarkdownRender';

export default function ArticleDetailPage() {
  const params = useParams() as {id: string};
  const queryByIdHook = ArticleHook.queryById();
  useEffect(() => {
    if (params.id) {
      queryByIdHook
        .request({id: parseInt(params.id)})
        .then()
        .catch(e => Toast.show(e.message));
    }
  }, [params]);
  return (
    <div>
      {queryByIdHook.data && (
        <div className={'bg-white p-t-10 p-b-30'}>
          <div className={'m-l-20 m-r-20 b-b-1 p-b-15'}>
            <h2 className={'m-t-10 m-b-5'}>{queryByIdHook.data.title}</h2>
            <small className={'text-gray'}>发布时间: {moment(queryByIdHook.data.publishedTime).format('YYYY 年 MM 月 DD 日')}</small>
          </div>
          <div className={'p-l-15 p-r-15'}>
            <MarkdownRender md={queryByIdHook.data.content} />
          </div>
        </div>
      )}
    </div>
  );
}
