import React from 'react';
import {UserHook} from '../hooks/User';
import {useCountDown} from 'ahooks';
import {Button, Toast} from 'zarm';

export default function RequestSMSButton(props: {telephone: string}) {
  const requestSMSCodeHook = UserHook.requestSMSCode();
  const [countdown, setTargetDate] = useCountDown();

  function requestSMSCode() {
    requestSMSCodeHook
      .request({telephone: props.telephone})
      .then(() => {
        setTargetDate(Date.now() + 60 * 1000);
        Toast.show('验证码已发送');
      })
      .catch(e => Toast.show(e.message));
  }

  return (
    <Button onClick={requestSMSCode} disabled={requestSMSCodeHook.loading || countdown > 0} loading={requestSMSCodeHook.loading}>
      {countdown > 0 ? <span>再次获取({Math.round(countdown / 1000)}s)</span> : <span>获取验证码</span>}
    </Button>
  );
}
