import React, {useEffect, useState} from 'react';
import './index.less';
import studentGif from '../../assets/student.gif';
import circle from '../../assets/circle.png';
import contestHeader from '../../assets/contestHeader.gif';
import {Button} from 'zarm';
import {useHistory} from 'react-router-dom';
export default function KnowledgeContest2022IndexPage() {
  const history = useHistory();
  return (
    <div className={'knowledgeContest2022Page'}>
      <img className={'contestHeader'} src={contestHeader} alt="" />
      <div style={{textAlign: 'center', paddingBottom: 20}}>
        <Button onClick={() => history.push('/knowledgeContest2022/attend')}>点此报名</Button>
      </div>
      <div className={'contestPanel'}>
        <div className={'contestPanelTitle'}>比赛简介</div>
        <div>
          <h4>比赛主旨</h4>
          <p>为激发青少年对学习计算机科技的热情与兴趣，提升青少年信息素养，由我会主办的上海市青少年计算机知识竞赛将于2022年6月11日以在线网络形式举行。</p>
          <h4>参赛对象</h4>
          <p>对计算机知识有浓厚兴趣的中小学生。</p>
          <h4>比赛时间</h4>
          <p style={{fontWeight: 'bold'}}>2022年6月11日（周六）13:30-14:30。</p>
          <h4>比赛费用</h4>
          <p>
            本次竞赛<span style={{color: '#223cc2'}}>不收取任何费用</span>，若要求颁发获奖证书的，需自付工本费。
          </p>
        </div>
      </div>
      <div className={'contestPanel'}>
        <div className={'contestPanelTitle'}>形式与内容</div>
        <div>
          <h4>比赛形式</h4>
          <p>比赛以网络赛形式进行，选手在竞赛时间在电脑端登陆比赛系统即可答题，问题形式均为选择题。（网址为http://iai.sh.cn)</p>
          <h4>考察内容</h4>
          <p>考查内容包含计算机的软硬件基础知识、信息意识与信息安全、算法与流程图表述等内容，旨在考查学生计算机知识掌握程度与计算思维的运用能力。</p>

          <h4>评奖方法</h4>
          <p>根据参赛人数，一等奖10%，二等奖15%，三等奖20%。</p>
        </div>
      </div>

      <div className={'contestPanel'}>
        <div className={'contestPanelTitle'}>报名方式</div>
        <div>
          <h4>报名方式</h4>
          <p>
            点击上方报名按钮，填写手机号及学生信息即可报名。 <br />
            比赛时可通过手机号+验证码或手机号+密码方式登录系统。
          </p>
          <h4>咨询方式</h4>
          <p>
            电子邮箱：info@scs.sh.cn <br />
          </p>
        </div>
      </div>
      <img className={'circle01'} src={circle} alt="" />
      <img className={'circle02'} src={circle} alt="" />
    </div>
  );
}
