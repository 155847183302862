import React from 'react';
import {Panel, Toast} from 'zarm';
import {ArticleHook} from '../hooks/Article';
import {useMount} from 'ahooks';
import ArticleCard from '../components/Article/ArticleCard';

export default function IndexPage() {
  return (
    <div className={'bg-white p-b-15'}>
      <ArticleCard type={'活动简介'} />
      <ArticleCard type={'通知公告'} />
      <ArticleCard type={'条例条规'} />
      <ArticleCard type={'获奖名单'} />
    </div>
  );
}
