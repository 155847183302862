import React, {useMemo, useState} from 'react';
import {Icon, TabBar} from 'zarm';
import {useHistory, useLocation} from 'react-router-dom';
const TabIcon = Icon.createFromIconfont('//at.alicdn.com/t/font_2414387_s0tukdtqiyg.js');

export default function LayoutTabBar() {
  const history = useHistory();
  const location = useLocation();
  function goTo(key: string) {
    if (key === 'Profile') {
      history.push('/profile');
    }
    if (key === 'Index') {
      history.push('/');
    }
    if (key === 'Article') {
      history.push('/article');
    }
    if (key === 'KnowledgeContest2022') {
      history.push('/knowledgeContest2022');
    }
  }
  const activeKey = useMemo(() => {
    if (location.pathname.includes('profile')) return 'Profile';
    if (location.pathname.includes('article')) return 'Article';
    if (location.pathname.includes('knowledgeContest2022')) return 'KnowledgeContest2022';
    return 'Index';
  }, [location.pathname]);
  const visible = useMemo(() => {
    // if (location.pathname.includes('knowledgeContest')) return false;
    return true;
  }, [location.pathname]);

  return (
    <TabBar visible={visible} activeKey={activeKey} onChange={key => goTo(key as string)}>
      <TabBar.Item itemKey="Index" title="主页" icon={<TabIcon type="iconhome" />} />
      <TabBar.Item itemKey="KnowledgeContest2022" title="知识竞赛" icon={<TabIcon type="iconfile-common" />} />
      <TabBar.Item itemKey="Article" title="关于" icon={<TabIcon type="iconfile-common" />} />
      <TabBar.Item itemKey="Profile" title="我的" icon={<TabIcon type="iconuser" />} />
    </TabBar>
  );
}
