import {ScoreRecordPrize} from './ScoreRecord';
import {ProblemLevel} from './Problem';
import useGet from '../Http/useGet';
import usePost from '../Http/usePost';

export type UserAuthenticateStatus = '未认证' | '认证中' | '已认证' | '认证失败';
export type UserSchoolLevel = '小学' | '初中' | '高中';
export const AllUserAuthenticateStatus = ['未认证', '认证中', '已认证', '认证失败'];
export type UserAuthenticateType = '教练' | '选手';
export const AllCompetitorSchoolLevels = ['小学', '初中', '高中'];
export const AllCoachSchoolLevels = ['专科', '本科', '硕士', '博士'];
export const AllUserSchoolLevels = [...AllCompetitorSchoolLevels, ...AllCoachSchoolLevels];
export const AllUserAuthenticationPublicLevels = ['公开', '匿名', '隐藏'];
export type UserAuthenticationPublicLevel = '公开' | '匿名' | '隐藏';

export interface UserContestPointRecord {
  contestId: number;
  level: ProblemLevel;
  contestName: string;
  prize: ScoreRecordPrize;
  monthFactor: number;
  prizeFactor: number;
  levelFactor: number;
  point: number;
}

export interface UserAvatarConfig {
  topType?: string;
  accessoriesType?: string;
  hairColor?: string;
  facialHairType?: string;
  facialHairColor?: string;
  clotheType?: string;
  clotheColor?: string;
  eyeType?: string;
  eyebrowType?: string;
  mouthType?: string;
  skinColor?: string;
  graphicType?: string;
}
export interface IUser {
  id: number;
  username: string;
  legalName: string;
  createdTime: string;
  updatedTime: string;
  avatarUrl: string;
  avatarConfig: UserAvatarConfig;
  telephone: string;
  isAuthenticated: boolean;
  isSelfQuerying: boolean;
  isSu: boolean;
  gender: '男' | '女';
  birthDate: string;
  credentialType: '身份证' | '回乡证';
  credentialCode: string;
  schoolLevel: UserSchoolLevel;
  schoolEntranceYear: string;
  authenticationSubmittedTime: string;
  authenticationConfirmedTime: string;
  authenticationRejectedTime: string;
  authenticationRejectRemark: string;
  schoolName: string;
  studentCardImageList: Array<{path: string; url: string}>;
  authenticateStatus: UserAuthenticateStatus;
  contactEmail: string;
  contactTelephone: string;
  authenticateType: UserAuthenticateType;
  authenticationPublicLevel: UserAuthenticationPublicLevel;
  isShowHistory: boolean;
  contestPoint: number;
  contestPointUpdatedTime: string;
  contestPointRecordList: UserContestPointRecord[];
  isAttendKnowledge2021: boolean;
  isAttendKnowledge2022: boolean;
}
export interface IUserRegisterParams {
  username: string;
  password: string;
  telephone: string;
  code: string;
}
export interface IUserRequestSMSCodeParams {
  telephone: string;
}
export interface IUserLoginParams {
  username: string;
  password: string;
}
export interface IUserLoginWithTelParams {
  telephone: string;
  code: string;
}
export interface IUserFetchByTokenParams {
  token: string;
}
export interface IUserUpdateAuthenticationParams {
  authenticateType: UserAuthenticateType;
  legalName: string;
  gender: '男' | '女';
  birthDate: string;
  credentialType: '身份证' | '回乡证';
  credentialCode: string;
  schoolLevel: '小学' | '初中' | '高中';
  schoolEntranceYear: string;
  schoolName: string;
  contactEmail: string;
  contactTelephone: string;
  studentCardImagePathList: string[];
}
export class UserHook {
  static requestSMSCode() {
    return usePost<IUserRequestSMSCodeParams, void>({url: 'user/requestSMSCode'});
  }
  static register() {
    return usePost<IUserRegisterParams, IUser>({url: 'user/register'});
  }
  static attendKnowledgeContest2021() {
    return usePost<any, any>({url: 'user/attendKnowledgeContest2021'});
  }
  static attendKnowledgeContest2022() {
    return usePost<any, any>({url: 'user/attendKnowledgeContest2022'});
  }
  static login() {
    return usePost<IUserLoginParams, {token: string; user: IUser}>({url: 'user/login'});
  }
  static updateAuthentication() {
    return usePost<IUserUpdateAuthenticationParams, IUser>({url: 'user/updateAuthentication'});
  }
  static loginWithTel() {
    return usePost<IUserLoginWithTelParams, {token: string; user: IUser}>({url: 'user/loginWithTel'});
  }
  static fetchByToken() {
    return useGet<IUserFetchByTokenParams, IUser>({url: 'user/fetchByToken'});
  }
}
