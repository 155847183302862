import React from 'react';
import useAuth from '../../utils/AuthContext';
import {useHistory} from 'react-router-dom';
import CustomerError from '../../components/CustomerError';
import {Button, Cell, Icon, NoticeBar, Toast} from 'zarm';
import AuthenticationForm from '../../components/Profile/AuthenticationForm';
import {useBoolean} from 'react-hanger';
import {IUserUpdateAuthenticationParams, UserHook} from '../../hooks/User';
const ProfileAuthenticationPage: React.FunctionComponent<any> = props => {
  const auth = useAuth();
  const updateAuthenticationHook = UserHook.updateAuthentication();
  const isEditForm = useBoolean(false);

  function updateAuthentication(params: IUserUpdateAuthenticationParams) {
    updateAuthenticationHook
      .request(params)
      .then(res => {
        auth.setCurrentUser(res);
        Toast.show('已提交审核');
        isEditForm.setFalse();
      })
      .catch(e => {
        Toast.show(e.message);
      });
  }

  if (!auth.currentUser) return <CustomerError statusCode={403} title={'请登录后操作'} />;
  return (
    <div>
      {!isEditForm.value && (
        <div>
          {auth.currentUser.authenticateStatus === '未认证' && (
            <div className={'text-center p-35'}>
              <Icon type={'info-round-fill'} theme="primary" style={{fontSize: 80}} />
              <h2>你还未进行实名认证</h2>
              <p className={'text-gray'}>实名认证信息将会影响后续的奖项评定与证书发放，请尽快认证</p>
              <Button theme={'primary'} className={'m-t-10'} onClick={isEditForm.setTrue}>
                开始认证
              </Button>
            </div>
          )}
          {auth.currentUser.authenticateStatus === '认证中' && (
            <div className={'text-center p-t-35 p-l-35 p-r-35'}>
              <Icon type={'time'} theme="primary" style={{fontSize: 80}} />
              <h2>实名认证审核中</h2>
              <p className={'text-gray'}>认证需要 3~7 个工作日的时间，你也可以中途修改。</p>
            </div>
          )}
          {auth.currentUser.authenticateStatus === '认证失败' && (
            <div className={'text-center p-t-35 p-l-35 p-r-35'}>
              <Icon type={'info-round-fill'} theme="primary" style={{fontSize: 80}} />
              <h2>实名认证未通过</h2>
              <p className={'text-gray'}>{auth.currentUser.authenticationRejectRemark}</p>
            </div>
          )}
          {auth.currentUser.authenticateStatus === '已认证' && (
            <div className={'text-center p-t-35 p-l-35 p-r-35'}>
              <Icon type={'right-round-fill'} theme="success" style={{fontSize: 80}} />
              <h2>实名认证通过</h2>
              <p className={'text-gray'}>你的认证已经完成，如需修改则需要重新审核。</p>
            </div>
          )}
          {auth.currentUser.authenticateStatus !== '未认证' && (
            <div className={'p-b-35'}>
              <AuthenticationForm user={auth.currentUser} readonly />
              <div className={'text-center'}>
                <Button className={'m-t-10'} onClick={isEditForm.setTrue}>
                  修改
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {isEditForm.value && (
        <AuthenticationForm user={auth.currentUser} cancel={isEditForm.setFalse} loading={updateAuthenticationHook.loading} onFinish={updateAuthentication} />
      )}
    </div>
  );
};
export default ProfileAuthenticationPage;
