import React, {useContext} from 'react';
import axios, {AxiosInstance} from 'axios';
import useAuth from '../utils/AuthContext';
export interface IAxiosContext {
  instance: AxiosInstance;
  baseUrl: string;
  defaultErrorHandle?: (error: Error) => any;
}
const AxiosContext = React.createContext<IAxiosContext>({} as any);

export interface IAxiosProviderProps {
  instance: AxiosInstance;
  baseUrl: string;
  children?: any;
  defaultErrorHandle?: (error: Error) => any;
}

export function useAxios() {
  return useContext(AxiosContext);
}

export default function AxiosProvider(props: IAxiosProviderProps) {
  const auth = useAuth();
  props.instance.interceptors.request.use(config => {
    config.baseURL = props.baseUrl;
    if (auth.isLogin) {
      config.headers = {...config.headers, 'Application-Token': auth.token};
    }
    return config;
  });
  return (
    <AxiosContext.Provider value={{instance: props.instance, defaultErrorHandle: props.defaultErrorHandle, baseUrl: props.baseUrl}}>
      {props.children}
    </AxiosContext.Provider>
  );
}
