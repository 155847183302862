import * as React from 'react';
import Flex from '../Flex';
import {useContext} from 'react';
import {IUser} from '../../hooks/User';
import authenticationPng from '../../assets/authentication.png';
function Username(props: {user: IUser; className?: string; disabled?: boolean; disableEllipsis?: boolean}) {
  return (
    <Flex className={props.className} align={'center'}>
      {props.user.isAuthenticated && (
        <Flex align={'center'}>
          <img src={authenticationPng} alt="实名认证" style={{height: 14, width: 14, borderRadius: 4, marginRight: 3}} />
          <strong style={{maxWidth: props.disableEllipsis ? undefined : 80}}>
            {props.user.legalName === '匿名' ? props.user.username : props.user.legalName}
          </strong>
        </Flex>
      )}
      {!props.user.isAuthenticated && <strong style={{maxWidth: props.disableEllipsis ? undefined : 80}}>{props.user.username}</strong>}
    </Flex>
  );
}
export default Username;
